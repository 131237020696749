<template>
  <div>
    <tabs :tabList="tabList" @active-tab="activeTabName = $event" />

    <keep-alive>
      <component :is="activeTabName" />
    </keep-alive>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs'
import GoldInvestmentOrders from './GoldInvestmentOrders'
import GoldInvestmentWithdraws from './GoldInvestmentWithdraws'

export default {
  data() {
    return {
      activeTabName: 'GoldInvestmentOrders',
      tabList: [
        { id: 1, title: 'bought gold orders', name: 'GoldInvestmentOrders' },
        { id: 2, title: 'withdraw gold orders', name: 'GoldInvestmentWithdraws' }
      ]
    }
  },

  components: {
    Tabs,
    GoldInvestmentOrders,
    GoldInvestmentWithdraws
  }
}
</script>
